import React from "react"

import Layout from "../components/layout"
import { SEO } from "../components/seo"

const Page = () => {
  return (
    <Layout>
      <SEO
        title={`Terapia światłem LED`}
        description={`Innowacyjna procedura zabiegowa wykonywana w Balicka Clinic przy użyciu nowoczesnego urządzenia NOVASKIN LAMPA LED PDT.`}
      />

      <div className="offer-item">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Terapia światłem LED</h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                Światło LED pobudza energetycznie komórki do regeneracji i
                przebudowy tkanek. Pod jego wpływem zwiększa się synteza
                kolagenu w skórze, poprawia jej ukrwienie, wzrasta
                przepuszczalność naczyń krwionośnych i tworzą się nowe naczynia.
                Pobudzone też zostają naturalne procesy odpornościowe skóry.
              </p>
              <p className="offer-item-description">
                Światło LED zmniejsza ból i reakcje zapalne, wspomagając
                leczenie oparzeń i naprawę uszkodzonej skóry oraz inicjując
                procesy zapobiegające starzeniu. Fototerapia lampą jest też
                idealnym uzupełnieniem zabiegów medycyny i chirurgii
                estetycznej. Sprawia że skóra szybciej się regeneruje, niweluje
                siniaki, opuchliznę, skraca czas gojenia, redukuje ból i działa
                antybakteryjnie. Dzięki temu efekty zabiegów, czy operacji są
                szybciej widoczne, czas rekonwalescencji jest zdecydowanie
                skrócony, a efekt końcowy jest jeszcze bardziej spektakularny.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Charakterystyka światła</h1>
              <div className="separator-line-horizontal"></div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Światło Niebieskie 415nm:</h1>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>działa antybakteryjnie i przeciwzapalnie</li>
                  <li>
                    hamuje stany zapalne u pacjentów z trądzikiem pospolitym i
                    różowatym
                  </li>
                  <li>niszczy bakterie nie naruszając tkanek skóry</li>
                  <li>jednocześnie minimalizuje trądzik i leczy go</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Światło Zielone 560nm:</h1>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>rozjaśnia przebarwienia</li>
                  <li>gojenie i wyciszenie skóry</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Światło Czerwone 635nm:</h1>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                <li>wzmaga aktywność komórek</li>
                <li>przyspiesza metabolizm komórkowy i krążenie krwionośnych</li>
                <li>zmniejsza i łagodzi podrażnienia pozabiegowe</li>
                <li>przyspiesza gojenie się ran</li>
                <li>wpływa na poprawę elastyczności i sprężystości skóry</li>
                <li>opóźnia procesy starzenia</li>
                <li>redukuje zmarszczki oraz przebarwienia</li>
                <li>redukuje rozstępy, blizny i cellulit</li>
                <li>ujędrnia ciało</li>
                </ul>
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <h1 className="offer-item-title">Jak wygląda zabieg?</h1>
              <div className="separator-line-horizontal"></div>
              <p className="offer-item-description">
                LPL pozwala na naświetlanie kilkoma barwami światła w celu
                jednoczesnej terapii kilku schorzeń w pojedynczej sesji. Średnio
                zabieg trwa 5-15 minut. Naświetla się czystą skórę, bez
                makijażu. Pacjent leży tuż pod lampą nie odczuwa ciepła. Zabiegi
                są bezinwazyjne i nie wymagają specjalnego przygotowania. Do
                osiągnięcia optymalnego rezultatu może być koniecznych od 6 do
                20 zabiegów.
              </p>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Przeciwwskazania</p>
              <div className="separator-line-horizontal"></div>
              <div className="offer-item-description">
                <ul>
                  <li>padaczka</li>
                  <li>uczulenie na światło</li>
                  <li>porfiria</li>
                  <li>ciąża, karmienie piersią</li>
                  <li>nowotwory</li>
                  <li>przyjmowanie leków ulegających fotodegradacji</li>
                </ul>
                <br />* Aktywna opryszczka oraz każdy stan zapalny organizmu
                jest przeciwwskazaniem do wykonania zabiegu
              </div>
            </div>

            <div className="col-md-12 col-xs-12">
              <p className="offer-item-title">Zdjęcia</p>
              <div className="separator-line-horizontal"></div>
              <div class="offer-item-images">
                <img
                  src="../img/offer/terapia-swiatlem-led/images/1.png"
                  alt="Terapia światłem LED NOVASKIN LAMPA LED PDT"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/terapia-swiatlem-led/images/2.png"
                  alt="Terapia światłem LED NOVASKIN LAMPA LED PDT"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/terapia-swiatlem-led/images/3.jpg"
                  alt="Terapia światłem LED NOVASKIN LAMPA LED PDT"
                  className="offer-item-img"
                />
                <img
                  src="../img/offer/terapia-swiatlem-led/images/4.jpg"
                  alt="Terapia światłem LED NOVASKIN LAMPA LED PDT"
                  className="offer-item-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
